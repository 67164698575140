* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body,
textarea {
  font-family: Lato, sans-serif;
}
